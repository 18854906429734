import type {Flag} from '@sail/access';
import type {ConnectElementConfigEnabledKeyExcludingInternal} from '../../connect/ConnectJSInterface/ConnectElementList';

export const ComponentOverrideFlag: Record<
  ConnectElementConfigEnabledKeyExcludingInternal,
  Flag
> = {
  account_management: 'enable_account_management_component' as const,
  account_onboarding: 'enable_account_onboarding_component' as const,
  app_onboarding: 'enable_app_onboarding_component' as const,
  app_install: 'enable_app_install_component' as const,
  app_viewport: 'enable_app_viewport_component' as const,
  balances: 'enable_balances_component' as const,
  capital_financing: 'enable_capital_financing_component' as const,
  capital_financing_application:
    'enable_capital_financing_application_component' as const,
  capital_financing_promotion:
    'enable_capital_financing_promotion_component' as const,
  capital_overview: 'enable_capital_overview_component' as const,
  payment_disputes: 'enable_payment_disputes_component' as const,
  documents: 'enable_documents_component' as const,
  instant_payouts: 'enable_instant_payouts_component' as const,
  issuing_card: 'enable_issuing_card_component' as const,
  issuing_cards_list: 'enable_issuing_cards_list_component' as const,
  issuing_cardholder_create:
    'enable_issuing_cardholder_create_component' as const,
  notification_banner: 'enable_notification_banner_component' as const,
  payment_details: 'enable_payment_details_component' as const,
  payment_method_settings: 'enable_payment_method_settings_component' as const,
  payments: 'enable_payments_component' as const,
  payouts: 'enable_payouts_component' as const,
  payouts_list: 'enable_payouts_list_component' as const,
  product_tax_code_selector:
    'enable_product_tax_code_selector_component' as const,
  tax_registrations: 'enable_tax_registrations_component' as const,
  tax_settings: 'enable_tax_settings_component' as const,
  tax_threshold_monitoring:
    'enable_tax_threshold_monitoring_component' as const,
  export_tax_transactions: 'enable_export_tax_transactions_component' as const,
  transactions_list: 'enable_transactions_list_component' as const,
  financial_account: 'enable_financial_account_component' as const,
  financial_account_transactions:
    'enable_financial_account_transactions_component' as const,
  // TODO(@stevekaliski): Deprecate
  treasury_financial_account:
    'enable_treasury_financial_account_component' as const,
  recipients: 'enable_recipients_component' as const,
  reporting_chart: 'enable_reporting_chart_component' as const,
};

// These are client-side flags received from changing the URL query params like so ?enable_developer_ids=true
// Flags in this list cannot be updated via go/flags and are meant only for testing purposes, so they aren't meant to be temporary
// These should be kept in sync with src/access.tsx if they are debug only.
export const URL_FLAGS = {
  ENABLE_DEVELOPER_IDS: 'enable_developer_ids' as const, // Show ID of Stripe objects, e.g. ch_, pi_, etc.
  SHOW_ARK_DEBUG_TOOLS: 'show_ark_debug_tools' as const, // Controls whether ARK's debugging tools are turned on for onboarding.
  ENABLE_UICONFIG_COPY_LINK: 'enable_uiconfig_copy_link' as const, // Enables copy link button in ui config component
  STRIPE_INTERNAL_OVERRIDE_FLAGS_IN_EXTERNAL_DOMAINS:
    'stripe_internal_override_flags_in_external_domains' as const, // Enables retrieving fresh account link on reload
  ENABLE_SUS_DUAL_REPORTING: 'enable_sus_dual_reporting' as const, // Enables double reporting for metrics/analytics, etc. (legacy vs new path)
  ENABLE_SESSIONS_DEMO: 'enable_sessions_demo' as const, // Hides testmode banners and rename testmode bank accounts.
  ENABLE_DEV_NOTICES: 'enable_dev_notices' as const, // Enables dev notices in the UI (used in connect-test and login as)
};

export const SERVER_CONTROLLED_FLAGS = {
  // Server side controlled behavior: these control whether some features work for certain account configurations
  ENABLE_PERSONALIZATION_DESIGN_SELECTION_ON_ISSUING_CARD_CREATION:
    'enable_personalization_design_selection_on_issuing_card_creation' as const,
  ENABLE_ISSUING_API: 'enable_issuing_api' as const,
  ENABLE_TREASURY_API: 'enable_treasury_api' as const,
  ENABLE_TREASURY_TESTMODE: 'enable_treasury_testmode' as const,
  ENABLE_TAX_FORMS_SECTION_SUS: 'enable_tax_forms_section_sus' as const,
  INSTANT_PAYOUTS_RTP_DISABLED: 'instant_payouts_rtp_disabled' as const, // disables Instant Payouts to US Banks via RTP and displays outage banner
  INSTANT_PAYOUTS_TO_DEBIT_CARDS_DISABLED:
    'instant_payouts_to_debit_cards_disabled' as const, // disables Instant Payouts to debit cards and displays outage banner
  CARD_PRESENT_BRING_YOUR_OWN_GATEWAY_PARAM_DOCS:
    'card_present_bring_your_own_gateway_param_docs' as const, // enables showing links to BYOG docs

  // Flags - these are temporary and are used for feature enablement
  ENABLE_TOPUPS: 'enable_topups' as const,
  ENABLE_ADD_LINKED_ACCOUNTS: 'enable_add_linked_accounts' as const, // Enables the functionality to add linked accounts in account management
  ENABLE_DEBIT_CARD_ENTRY: 'enable_debit_card_entry' as const, // Enables user to select debit card as an external account
  BANK_CONNECTIONS_IN_ONBOARDING_UNIFIED:
    'bank_connections_in_onboarding_unified' as const, // should we show bank connections in the external account composition in onboarding?
  PLATFORM_EXTERNAL_ACCOUNT_SETTINGS_ENABLED:
    'platform_external_account_settings_enabled' as const, // uses Platform External Account Settings (PEAS) for LEAF settings
  ENABLE_BANK_ACCOUNT_VERIFICATION_ALTERNATIVE_METHODS:
    'enable_bank_account_verification_alternative_methods' as const, // Enable alternative methods (FC, re-key) in the UI for resolving Bank Acocunt Verification
  BAV_ENABLE_FC_OVERRIDE_PEAS: 'bav_enable_fc_override_peas' as const, // Enable Financial Connections in the Bank Account Verification flow, even if it is otherwise turned off by Platform External Account Settings
  FINANCIAL_CONNECTIONS_ENABLE_CA_ACCOUNTS:
    'financial_connections_enable_ca_accounts' as const, // Enable Financial Connections in the Bank Account Verification flow, for Canadian bank account collection.
  ENABLE_BANK_ACCOUNT_VERIFICATION_UPLOAD_FLOW:
    'enable_bank_account_verification_upload_flow' as const, // allows the bank account document upload to be a separate composition in remediation flow
  ENABLE_BANK_ACCOUNT_VERIFICATION_NAME_UPDATE:
    'enable_bank_account_verification_name_update' as const, // allows the ability to change name fields in-line in the UI for resolving Bank Acocunt Verification
  ENABLE_EMBEDDED_ONBOARDING_RESPONSIVE_DESIGN:
    'enable_embedded_onboarding_responsive_design' as const,
  ENABLE_EMBEDDED_BALANCES_COMPONENT_REDESIGN:
    'enable_embedded_balances_component_redesign' as const,
  ENABLE_PAYOUT_SCHEDULE_ONBOARDING_ARK_V2_EMBEDDED_FLAG:
    'enable_payout_schedule_onboarding_ark_v2_embedded_flag' as const,
  ENABLE_PAYOUT_SCHEDULE_ONBOARDING_ARK_V2_EMBEDDED_FLAG_GLOBAL:
    'enable_payout_schedule_onboarding_ark_v2_embedded_flag_global' as const,
  IR_KNEE_INVENT_MANUAL_BANK_ACCOUNT_ONBOARDING_FLAG:
    'ir_knee_invent_manual_bank_account_onboarding_flag' as const,
  ARKV2_DISABLE_DISABLE_SUBMIT_BUTTON_ON_FRONTEND_VALIDATION_ERRORS:
    'arkv2_disable_disable_submit_button_on_frontend_validation_errors' as const,
  ENABLE_CONNECT_MOBILE_SDK_PRIVATE_PREVIEW:
    'enable_connect_mobile_sdk_private_preview' as const,
  USE_IFRAME_OVERRIDES_FROM_SAIL: 'use_iframe_overrides_from_sail' as const,
  ENABLE_SAIL_BOTTOM_SHEETS: 'enable_sail_bottom_sheets' as const,
  ENABLE_DISPUTE_EVIDENCE_SUBMISSION_FEE:
    'enable_dispute_evidence_submission_fee' as const,
  DISABLE_DISPUTE_EVIDENCE_SUBMISSION_FEE:
    'disable_dispute_evidence_submission_fee' as const,
  ENABLE_DISPUTE_REPRICING_MERCHANT_XP:
    'enable_dispute_repricing_merchant_xp' as const,
  VISA_APRIL_2025_COMPLIANCE_FEE_UPDATE:
    'visa_april_2025_compliance_fee_update' as const,
  ENABLE_ACCOUNT_MANAGEMENT_WITHOUT_ACCOUNT_SUBMISSION:
    'enable_account_management_without_account_submission' as const,
};

export const FLAGS = {
  ...SERVER_CONTROLLED_FLAGS,
  ...URL_FLAGS,
  ...ComponentOverrideFlag,
};

// This type exists to ensure that FLAGS has only flags as values
export const doNotImportEnsureObjectOnlyContainsFlags: Record<string, Flag> =
  FLAGS;
