import * as React from 'react';
import {SafeAnchor} from '@stripe-internal/safe-links';
import {assignProps} from './assignProps';
import {compareProps} from './compareProps';
import {$context, $hasView, Priority} from './constants';
import {
  createSubviewIntents,
  populateSubviewIntents,
} from './createSubviewIntents';
import {createViewOptionsIntents} from './createViewOptionsIntents';
import {runProviders} from './provider';
import {runDomIntents} from './dom';
import {applyDefaults} from './setDefaults';
import {applyOmitProps} from './omitProps';
import {createViewInstance, extendViewInstance} from './ViewInstance';
import {
  addIntentsToRevision,
  assignObjectProps,
  createViewRevision,
  getSubviewIntentsFromProps,
  getViewRevisionFromProps,
} from './ViewRevision';
import {getRegisteredCssSerializer} from './css';
import {
  getElementRef,
  getViewComponent,
  isView,
  setActiveViewRevision,
} from './util';
import {getViewConfigIntents, getViewConfigIntentType} from './ConfigIntent';
import {mergeIntents, provider} from '../intent';
import {hash, useConstant, useIsomorphicLayoutEffect} from '../util';
import {StyleSheets} from '../stylesheets';
import type {View} from './types';
import type {Intent} from '../intent';

/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * Sail’s entry point for creating components. Views extend React components to make them more configurable, styleable, and easily composable by default.
 *
 * @see https://sail.corp.stripe.com/next/apis/createview
 */
export const createView = createViewImplementation as View.CreateView;

/**
 * Create views that support a variety of user-provided types.
 *
 * @see https://sail.corp.stripe.com/next/apis/creategenericview
 */
export const createGenericView =
  createViewImplementation as View.CreateView<false>;
/* eslint-enable @typescript-eslint/no-use-before-define */

const priorities = [Priority.High, Priority.Normal];
const isTestEnv = process.env.NODE_ENV === 'test';

function isFunctionComponent<Props>(
  Component: string | React.ComponentType<Props>,
): Component is (props: Props) => JSX.Element | null {
  return (
    typeof Component === 'function' &&
    // https://overreacted.io/how-does-react-tell-a-class-from-a-function/
    !Component.prototype?.isReactComponent
  );
}

function normalizeIntrinsicComponent<Props>(
  Component: string | React.ComponentType<Props>,
): string | React.ComponentType<Props> {
  return Component === 'a'
    ? (SafeAnchor as React.ComponentType<Props>)
    : Component;
}

export function createViewFromConfig<Props, Options extends View.ConfigOptions>(
  config: View.Config<Props, Options>,
  render: string | React.ComponentType<Props>,
  viewOptions?: View.ViewOptions<Props>,
): (props: View.ViewProps<Props>) => JSX.Element | null {
  if (config[$hasView]) {
    throw new Error('ViewConfig.createView can only be called once.');
  }
  config[$hasView] = true;

  const {options} = config;

  const isIntrinsic = typeof render === 'string';

  let displayName = options.name ?? '';
  displayName ||= isIntrinsic
    ? `view.${render}`
    : render.name || render.displayName || 'AnonymousView';

  const getViewIntents = (() => {
    let intents: View.Intent<Props>[];
    return () => {
      intents ??= viewOptions
        ? createViewOptionsIntents(config, viewOptions)
        : [];
      return intents;
    };
  })();

  function assignRefProp(
    revision: View.ViewRevision<Props>,
    ref?: View.InferRef<Props>,
  ): void {
    addIntentsToRevision(
      revision,
      [assignProps({ref}) as Intent<Props>],
      Priority.High,
    );
  }

  // Create the view’s render method. This contains the bulk of the view’s
  // rendering logic and can be automatically collapsed into a parent view
  // using the `ViewConfig.returns` property.
  function useView(
    props: Props,
    instance: View.ViewInstance<Props>,
    forwardedRef: View.InferRef<Props>,
  ): JSX.Element | null {
    const inheritedRevision = getViewRevisionFromProps(props);
    const subviewIntents = getSubviewIntentsFromProps(props);
    const subviewsProp = (props as {subviews: View.SubviewProps<unknown>})
      .subviews;

    const isStaticElement =
      options.element === true || (isIntrinsic && options.element === 'auto');

    // Create a revision for the current render
    const revision = createViewRevision<Props>(
      instance,
      render,
      props,
      forwardedRef,
      isStaticElement,
    );
    setActiveViewRevision(revision);

    useIsomorphicLayoutEffect(() => {
      // When the ref is updated, we know React has committed the element.
      // Update the ref stored on the instance to reflect the committed state.
      if (process.env.NODE_ENV !== 'production') {
        // Freeze the revision and ref in non-production environments to prevent
        // users from mutating them after they've been committed.
        Object.freeze(revision);
      }
      instance.committedRevision = revision;

      // Commit any scheduled styles. Styles are batched according to the same
      // heuristic as React.useInsertionEffect.
      // https://react.dev/reference/react/useInsertionEffect
      StyleSheets.commit();
    });

    // Merge subview props
    if (subviewIntents?.props) {
      assignObjectProps(revision.props, subviewIntents.props);
    }

    // Merge the inherited ref into props
    if (inheritedRevision?.ref) {
      assignRefProp(revision, inheritedRevision.ref);
    }

    // Merge the subview ref into props
    if (subviewIntents?.ref) {
      assignRefProp(revision, subviewIntents.ref);
    }

    // Apply any default values from the view config
    if (options.defaults) {
      applyDefaults(revision, options.defaults as Props);
    }

    const configIntents = options.context
      ? // It's safe to run hooks here because `options` is constant
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useContext(config[$context])
      : undefined;

    const inheritedConfigIntents = inheritedRevision
      ? getViewConfigIntents(revision, inheritedRevision)
      : undefined;

    for (let i = 0; i < priorities.length; i++) {
      const priority = priorities[i];
      const isNormal = priority === Priority.Normal;

      // Add any intents passed to createView
      addIntentsToRevision(revision, getViewIntents(), priority);

      // Add any intents from the view config
      addIntentsToRevision(revision, configIntents, priority);

      // Add any intents from the `uses` prop
      const viewProps = props as unknown as View.ViewProps<Props>;
      addIntentsToRevision(
        revision,
        viewProps.uses as View.Intent<Props>[],
        priority,
      );

      if (isNormal) {
        // Add any styles from the `css` prop
        if (viewProps.css) {
          const css = getRegisteredCssSerializer(options.css);
          if (css) {
            addIntentsToRevision(revision, [css(viewProps.css)], priority);
          }
        }
      }

      // Add any inherited intents bound to the current config.
      // This includes dynamic intents which will run in the correct order.
      addIntentsToRevision(revision, inheritedConfigIntents, priority);

      // Add any inherited subview intents
      addIntentsToRevision(revision, subviewIntents, priority);
    }

    if (inheritedRevision) {
      // Merge any remaining intents from the inherited revision. This
      // ensures that intents added by ancestor components take precedence over
      // intents added in the current component.
      //
      // This excludes:
      // * intents bound to the current config, which have already been added
      // * subview intents, which are provided via the `subviews` prop
      const configIntentType = getViewConfigIntentType(revision);
      mergeIntents(
        revision,
        inheritedRevision,
        (intentType) =>
          intentType !== configIntentType &&
          intentType !== createSubviewIntents,
      );
    }

    // Populate any subviews
    if (!isStaticElement) {
      populateSubviewIntents(revision, subviewsProp, config.options);
    }

    // Omit any props that should be excluded from the render method
    applyOmitProps(revision);

    const refProps = revision.props as {ref?: unknown};
    if (!isStaticElement && 'ref' in refProps) {
      revision.ref = refProps.ref as View.InferRef<Props>;
      delete refProps.ref;
    }

    if (isStaticElement) {
      // Run DOM intents if we know this is a DOM element
      runDomIntents(revision);
    }

    // Call the render method and create the React element
    const Component = revision.Component;
    let element: JSX.Element | null;
    if (isFunctionComponent(Component)) {
      element = Component(revision.props);
    } else if (isView<Props>(Component)) {
      // Automatically flatten any views passed in as a render method.
      // It's safe to run hooks here because `Component` is constant
      // eslint-disable-next-line react-hooks/rules-of-hooks
      element = Component.useView(
        revision.props,
        extendViewInstance(instance, Component.config),
        revision.ref,
      );
    } else {
      element = React.createElement(
        normalizeIntrinsicComponent(Component) as React.ComponentType<object>,
        revision.props as object,
      );
    }

    // If automatic element detection is enabled, check whether the ref is
    // attached to the outermost React element. If that element is not a view,
    // clone the element and run DOM intents
    let isElement = isStaticElement;
    if (!isIntrinsic && options.element === 'auto' && element) {
      const innerView = getViewComponent(element.type);
      const innerRevision = getViewRevisionFromProps(element.props);
      if (!innerView && innerRevision === revision) {
        // Update the revision to use the returned element's props and component
        // This allows DOM intents to merge props and wrap components safely
        (revision as View.MutableViewRevision<Props>).Component = element.type;
        revision.props = assignObjectProps(
          {key: element.key, ref: revision.ref},
          element.props,
        );

        const elementRef = getElementRef(element) as View.InferRef<Props>;
        if (elementRef) {
          assignRefProp(revision, elementRef);
        }

        runDomIntents(revision);
        isElement = true;

        // We can't use cloneElement here, because it shallowly merges with
        // the original props:
        //   https://react.dev/reference/react/cloneElement#returns
        element = React.createElement(
          normalizeIntrinsicComponent(
            revision.Component as React.ComponentType,
          ),
          revision.props as object,
        );
      }
    }

    if (options.flattens && !isElement) {
      const innerView = getViewComponent(element?.type);
      const innerRevision = element && getViewRevisionFromProps(element.props);

      // Check that the returned element is a view with an `inherits` prop
      if (!element || !innerView) {
        throw new Error(
          `Cannot flatten <${displayName}>: the returned element must include the \`inherits\` prop.`,
        );
      }

      // Check that the return element receives an `inherits` prop that matches the current revision.
      if (innerRevision !== revision) {
        throw new Error(
          `Cannot flatten <${displayName}>: the returned element must receive the unmodified value of the \`inherits\` prop.`,
        );
      }

      // The user has guaranteed that this view will always be returned.
      // Flatten the returned view into the current render method by calling
      // its `useView` hook.
      element = innerView.useView(
        element.props,
        extendViewInstance(instance, innerView.config),
        getElementRef(element),
      );
    }

    if (isElement) {
      // Wrap the React element with context providers (using intents) if this
      // is a DOM element
      element = runProviders(revision, element);
    }

    return element;
  }

  // Store the component’s render method in a variable so we can set the
  // display name.
  function ViewComponent(
    props: Props,
    forwardedRef: React.ForwardedRef<unknown>,
  ) {
    // This cache allows us to maintain stable identities of computed values
    // across renders
    const instance = useConstant(() =>
      createViewInstance<Props, Options>(config),
    );
    try {
      return useView(props, instance, forwardedRef as View.InferRef<Props>);
    } finally {
      // Reset the current revision (including when a component suspends)
      setActiveViewRevision(undefined);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (ViewComponent as any).displayName = displayName;

  const view = (options.memoize
    ? React.memo(React.forwardRef(ViewComponent), compareProps)
    : React.forwardRef(ViewComponent)) as unknown as View.ViewComponent<
    (props: View.ViewProps<Props>) => JSX.Element | null
  >;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  view.config = config as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  view.useView = useView as any;

  // Set the outer component's displayName in test environments to make it easy
  // for Enzyme to query components by name. We allow the displayName to be
  // autogenerated in development and production so that React Developer Tools
  // can properly display Memo and ForwardRef markers on components.
  if (isTestEnv) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (view as any).displayName = displayName;
  }

  return view;
}

/**
 * Sail’s extension point for customizing components.
 *
 * Sail enables components to adapt their default styles and behaviors based
 * on the context in which components are used. Views created from a view
 * config internally use [React context](https://reactjs.org/docs/context.html)
 * to receive data and instructions from ancestor views.
 *
 * @see https://sail.stripe.me/apis/createviewconfig
 */
export function createViewConfig<
  Props = {children?: React.ReactNode},
  Options extends View.ConfigOptions<Props> = View.ConfigOptions<Props>,
>(
  options: {props?: Props} & (Options extends Record<string, never>
    ? Options
    : View.NarrowConfig<Options>),
): View.Config<Props, Options> {
  /**
   * We infer `Options` to allow us to know exactly which props are passed to
   * `defaults` and `css`. This allows us to then mark default props as
   * optional in the resulting view and correctly type check the `css` prop.
   *
   * TypeScript doesn't allow some type parameters to be provided and others to
   * be inferred; it's all or nothing. To work around this, we use the `props`
   * field to allow users to declare the type of props while still inferring
   * the `Options` type.
   *
   * In the future, we may be able to achieve this with the upcoming `satisfies`
   * keyword in TypeScript 4.9+
   * https://github.com/microsoft/TypeScript/issues/26242
   */
  (options as Options).context ??= true;
  (options as Options).element ??= 'auto';
  (options as Options).memoize ??= false;
  (options as Options).name ??= '';

  const ViewContext = React.createContext([] as View.Intent<Props>[]);
  ViewContext.displayName = `${options.name}Config`;

  const config = {
    _id: hash(JSON.stringify(options)),
    options: options as Options,
    [$context]: ViewContext,
    [$hasView]: false,

    createView: function (
      render: (props: Props) => JSX.Element | null,
      viewOptions?: View.ViewOptions<Props, Options>,
    ) {
      return createViewFromConfig(config, render, viewOptions);
    } as View.ConfigCreateView<Props, Options>,

    customize(viewOptions) {
      return provider(config, viewOptions) as Intent;
    },

    Customize({children, ...value}) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return useProvider(children, value);
    },

    Provider({children, value}) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return useProvider(children, value);
    },
  } as View.Config<Props, Options>;

  function useProvider(
    children: React.ReactNode,
    value: View.CustomizeViewOptions<Props, Options>,
  ): JSX.Element {
    const intents = createViewOptionsIntents<Props, Options>(config, value);
    const inherited = React.useContext(config[$context]);
    const merged = React.useMemo(
      () => inherited.concat(intents),
      [inherited, intents],
    );
    return React.createElement(ViewContext.Provider, {value: merged}, children);
  }

  config.createGenericView = config.createView;

  (
    config.Customize as {displayName?: string}
  ).displayName = `${options.name}Config.Customize`;
  (
    config.Provider as {displayName?: string}
  ).displayName = `${options.name}Config`;

  return config;
}

function createViewImplementation<Props>(
  render: string | ((props: Props) => JSX.Element | null),
  viewOptions?: View.ViewOptions<Props>,
): (props: View.ViewProps<Props>) => JSX.Element | null {
  return createViewFromConfig(
    createViewConfig({
      props: {} as Props,
      context: false,
      element: typeof render === 'string' ? true : 'auto',
    }),
    render,
    viewOptions,
  );
}

export function isViewConfig<Props, Options extends View.ConfigOptions<Props>>(
  value: unknown,
): value is View.Config<Props, Options> {
  return !!value && $context in (value as View.Config<Props, Options>);
}
